import { template as template_e45c4494d0e24f3ea1d8ad943dcd70b7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_e45c4494d0e24f3ea1d8ad943dcd70b7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
