import { template as template_918fd2dd86ee41c984874d6060f93523 } from "@ember/template-compiler";
const FKControlMenuContainer = template_918fd2dd86ee41c984874d6060f93523(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
