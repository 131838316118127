import { template as template_e4c76ff58a7742baa7d51b5dafcf7c00 } from "@ember/template-compiler";
const FKText = template_e4c76ff58a7742baa7d51b5dafcf7c00(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
